import React from 'react';

const RepaymentMortgages = () => {
  return (
    <div>
          <div className="takeaways">
            <h2>Key Takeaways</h2>
            <ul>
              <li>An interest-only mortgage means your payments will only go towards the bank’s interest so at the end of you’ll still owe the full amount</li>
              <li>
              With a repayment mortgage, your monthly payments are higher but your payments go towards the debt and your balance is reduced over time
              </li>
              <li>
              Interest-only mortgages may be suitable for people who have a large chunk of their income paid by a bonus
              </li>
            </ul>
          </div>
          <div className="transcript">
            <h2>Transcript</h2>
            <p>
            What is the difference between interest-only and repayment mortgages?<br/><br/>
            When you come to think about what mortgage might be best for you, one of the things to think about is interest only versus repayment.<br/><br/>
            Let's have a look at an interest-only mortgage. With an interest-only mortgage, you'll see that when you borrow, say, £100,000, your monthly payments go towards just servicing the bank's interest.<br/><br/>
            So at the end of the 20-year term, you will still owe the bank that £100,000. Now, let's have a look at a repayment mortgage. With a repayment mortgage, your monthly payment is higher because the payment covers the bank's interest but also goes towards chipping away at the debt. <br/><br/>
            This means that with the repayment mortgage, your balance gradually reduces over time and at the end of the 20-year term, you end up owing nothing at all and own the property mortgage-free.<br/><br/>
            You may think, why on earth would you want an interest-only mortgage if you still owe the bank the money at the end of the term?<br/><br/>
            One reason could be that a large chunk of your income might be paid by a bonus. So it might be better for your cash flow to keep the monthly payments lower and then pay down a big chunk of your mortgage through your bonus at the end of the year.<br/><br/>
            </p>
          </div>
    </div>
  );
};

export default RepaymentMortgages;
