import React from 'react';

const FixedVariable = () => {
  return (
    <div>
          <div className="takeaways">
            <h2>Key Takeaways</h2>
            <ul>
              <li>A fixed-rate mortgage means the interest rate will stay the same during the mortgage term.</li>
              <li>
              Fixed-rate mortgages offer the security of knowing what your mortgage payment will be over time of the mortgage, but if interest rates fall you won’t benefit from the lower rate.
              </li>
              <li>
              Variable-rate mortgages offer flexibility if interest rates drop so will your mortgage rate and monthly payments. However, there is no security of your payments staying the same.
              </li>
            </ul>
          </div>
          <div className="transcript">
            <h2>Transcript</h2>
            <p>
              What is the difference between a fixed and variable-rate mortgage?<br/><br/>
              When it comes to choosing between a fixed or a variable-rate mortgage, there are pros and cons to each option.<br/><br/>
              A fixed-rate mortgage offers the security of knowing how much your monthly payments will be set for a period of time, usually between two and five years.<br/><br/>
              This means you can budget more effectively and won't be affected by any interest rate rises during the period that you're fixed within. However, if interest rates fall during that time, you won't be benefitting from that lower rate.<br/><br/>
              A variable rate, on the other hand, can offer more flexibility. Your monthly payments will go up or down in line with any changes to the Bank of England base rate. If the interest rates fall, you benefit from lower mortgage payments.<br/><br/>
              However, if interest rates rise, your mortgage payments will also increase. Deciding which option is better for you will depend on your personal circumstances and risk tolerance.<br/><br/>
              If you value the security of knowing exactly what your mortgage payments will be, a fixed-rate mortgage may be the better solution.<br/><br/>
              However, if you're willing to take on a bit more risk and hope to benefit from potential interest rate cuts, a variable-rate mortgage may be more suitable.<br/><br/>
              It's important to note that fixed-rate mortgages often come with higher rates than variable-rate mortgage.<br/><br/>
              As you are paying for the security of knowing exactly what your payments will be, make sure you speak to a mortgage adviser to compare the deals from different lenders and find the best option for your individual needs.<br/><br/>
            </p>
          </div>
    </div>
  );
};

export default FixedVariable;
