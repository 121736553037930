import React from 'react';

const FixedVariableFAQ = () => ([
    {
      title: 'What is a fixed-rate mortgage? What are the pros and cons?',
      description: (
        <>
          A fixed-rate mortgage where the monthly repayments stay the same during the whole period of the mortgage term.<br /><br />
          The main benefit of a fixed-rate mortgage is you have security knowing what your mortgage payments are going to be for the whole length of the mortgage.<br /><br />
          The disadvantage of a fixed-rate mortgage is that if interest rates fall you won't benefit from your monthly payments dropping.
        </>
      ),
    },
    {
      title: 'What is a variable rate mortgage? What are the pros and cons?',
      description: (
        <>
          A variable-rate mortgage is where the interest rate on your mortgage can increase or decrease during the term of your mortgage, usually following the Bank of England base rate.<br/><br/>
          There are two types of variable-rate mortgages. A tracker mortgage follows the Bank of England base rate, and a standard variable mortgage follows the lender's standard variable rate.<br /><br />
          The pros of a variable rate mortgage are that if interest rates fall then you'll benefit from your mortgage rates lowering and your mortgage monthly payments being lower.<br /><br />
          The disadvantage of a variable rate mortgage is that the variable rates can change, so you'll need to be prepared for any changes to your mortgage repayments if interest rates change, unlike with a fixed rate.
        </>
      ),
    },
    {
      title: 'Are there early repayment charges on all types of mortgages?',
      description: (
        <>
          Not all mortgage types come with an early repayment charge, so if this is something that you're looking to avoid there is the option of finding a deal that doesn't have ERC associated.<br/><br/>
          A standard variable rate mortgage usually doesn't have early repayment charges. However, you are likely going to be on a higher interest rate for this.
        </>
      ),
    },
    {
      title: 'What type of mortgage deal is right for me?',
      description: (
        <>
          The type of mortgage depends on your personal circumstances and preferences so it's best to speak to a mortgage broker to understand what the best option is for you.<br/><br/>
          Typically, if you are looking for security in your monthly payments fixed-rate mortgages are a good option, whereas if you're looking for more flexibility and open to taking more of a risk, then a variable-rate mortgage could be more suitable.<br /><br />
          Lenders offer different types of mortgages so it's always a good idea to look around to see what is available and right for you.
        </>
      ),
    },
    {
      title: 'Can Sprive help me with a fixed-rate mortgage or a variable-rate mortgage?',
      description: (
        <>
          Whether you are looking for a fixed-rate deal or a variable mortgage, Sprive can help you find the right remortgage deal for you. We'll put you in touch with a vetted mortgage broker to help you decide the right type of mortgage for you and help you remortgage.
        </>
      ),
    },
  ]);

export default FixedVariableFAQ;
