import React from 'react';

const GreenMortgages = () => {
  return (
    <div>
          <div className="takeaways">
            <h2>Key Takeaways</h2>
            <ul>
              <li>Green mortgages are offered by Banks and Building societies that reward you for taking out a mortgage based on the Energy Performance Certificate rating on your home. </li>
              <li>
              There are a few things that you can do to improve how energy-efficient your home is. Such as installing double-glazed windows or adding insulation to the roof.
              </li>
              <li>
              The benefit of a green mortgage is you can qualify for reduced mortgage rates compared to the regular product offering since the banks want to reward you for having an energy-efficient home.
              </li>
            </ul>
          </div>
          <div className="transcript">
            <h2>Transcript</h2>
            <p>
            What are green mortgages?<br/><br/>
            Green mortgages are offered from banks and building societies that reward you for taking out a mortgage based on the Energy Performance Certificate rating on your home.<br/><br/>
            An Energy Performance Certificate confirms the energy efficiency of your property is, and the higher the rating, the lower the energy running costs on your property.<br/><br/>
            There are a few things that you can do to improve the energy efficiency your home, such as double-glazed windows or added insulation to the roof.<br/><br/>
            However, an Energy Performance Consultant will be the best person to advise you on this. Typically, the acceptable EPC rating to qualify for a green mortgage should be between an A and a C.<br/><br/>
            An Energy Performance Certificate can be found from an online register or the sales agent, which can confirm if you are eligible for a green mortgage.<br/><br/>
            The benefit of having a green mortgage is that you qualify for reduced mortgage rates compared to the regular product offering since banks want to reward you for having an energy-efficient home.<br/><br/>
            </p>
          </div>
    </div>
  );
};

export default GreenMortgages;
