import React from 'react';

const SixStapesFAQ = () => ([
    {
      title: 'What is remortgaging?',
      description: (
        <>
          Remortgaging is the process of moving from your current mortgage deal to a new deal with the same lender or to a new lender. <br /><br />
          Remortgaging usually happens when the existing mortgage term comes to an end. However, you can also remortgage to release equity, exit early to move to a better interest rate to save money or move homes. However, if you remortgage early before your existing deal ends, you are likely to be charged an ERC, early repayment charge by the lender.
        </>
      ),
    },
    {
      title: 'How do I get a better deal when I remortgage?',
      description: (
        <>
          There may be some things you want to do before remortgaging to get access to a better deal.<br/><br/>
          Making overpayments on your existing deal in the run-up to your remortgage can lower your loan-to-value(TV) which will give you access to better rates.<br/><br/>
          Avoid taking out any new credit loans, and make sure you're paying off any debts where you can to help ensure your credit score is in the best shape it can be. You can read more about your credit score here.<br/><br/>
          Make sure that you keep up with your monthly payments and bills as if you need a new affordability check the lender will look out for these things.
        </>
      ),
    },
    {
      title: 'How do I know when it\'s the right time to remortgage?',
      description: (
        <>
          The best time to remortgage is typically when your current deal is ending or if interest rates have dropped since you took out your mortgage.<br/><br/>
          Usually, your current lender will get in touch and let you know you can remortgage.<br/><br/>
          It's a good idea to start looking at other mortgage deals about 3-6 months before your current deal expires as if you don't remortgage before your deal expires you will be put on the lender's standard variable rate (SVR) which is more expensive.
        </>
      ),
    },
    {
      title: 'Can I remortgage before my existing deal expires?',
      description: (
        <>
          You can usually switch to a new deal before your mortgage term comes to its end with your current lender, but it's important to note that this will most likely come with exit fees and early repayment charge (ERC).
        </>
      ),
    },
    {
      title: 'What is an early repayment charge (ERC)?',
      description: (
        <>
        An early repayment charge is a penalty that is charged by the lender. These charges are incurred when you exit your mortgage deal before the end of your mortgage deal term, pay off your mortgage faster or go over your overpayment threshold by making an early repayment on your mortgage.
        </>
      ),
    },
    {
      title: 'Are there fees when remortgaging?',
      description: (
        <>
        There can be fees such as arrangement fees, valuation fees, legal fees, and potential exit fees from your current lender. Be sure to factor in these costs when comparing new deals.<br /><br />
        It's also worth checking with your existing lender if there are any early repayment charges if you're considering moving to a new mortgage deal early.
        </>
      ),
    },
    {
      title: 'How much paperwork is involved in the remortgage process?',
      description: (
        <>
        While there is paperwork involved, it's often less than when you initially got your mortgage. You'll need to provide proof of income, details about your current mortgage, and other financial information.
        </>
      ),
    },
    {
      title: 'Is there an affordability check when I remortgage?',
      description: (
        <>
        Remortgaging with your existing lender sometimes means that you won't need to go through another affordability check, which is why some people choose to stay with their current lender.<br /><br />
        However, this can mean that you won't get access to as many mortgage deals as other lenders may have deals with better interest rates and terms.
        </>
      ),
    },
  ]);

export default SixStapesFAQ;
