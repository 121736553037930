import React from 'react';

const SixStapes = () => {
  return (
    <div>
          <div className="takeaways">
            <h2>Key Takeaways</h2>
            <ul>
              <li>Check your current deal and assess any charges</li>
              <li>
              What type of mortgage is best for you and compare different options
              </li>
              <li>
              Check your credit score is up-to-date and accurate
              </li>
              <li>
              Speak to a mortgage broker for advice on your personal circumstances
              </li>
            </ul>
          </div>
          <div className="transcript">
            <h2>Transcript</h2>
            <p>
              6 steps to remortgaging<br/><br/>
              Remortgaging can be a great way to save money on your mortgage, but finding the best deals can be overwhelming.<br/><br/>
              Here are 6 steps to help you find the best remortgage deal in the UK.<br/><br/>
              Number one, check your current deal before you start looking for a new mortgage, check your current deal. Look at the interest rate, monthly payments, and any fees or charges. This will help you understand what you're currently paying and what you could save by remortaging.<br/><br/>
              Number two, look at your options. There are a variety of remortgage options available, such as fixed rate, variable rate and tracker mortgages. Think about what type of mortgage would be best for you.<br/><br/>
              Number three, and check your credit score. Your credit score can affect your ability to get a good mortgage deal. Make sure your credit score is up to date and accurate before applying for a mortgage.<br/><br/>
              Number four, compare deals. Once you know what type of mortgage you want, start comparing deals from different lenders. Look at the interest rate, fees and charges and any special features or benefits offered.<br/><br/>
              Number five, speak to a mortgage broker. A mortgage broker can offer you advice and help you find the best deal for your individual circumstances. They can also help you understand the fees and the charges associated with remortgaging.<br/><br/>
              Number six, apply for the mortgage. Once you've found the right deal, it's time to apply. Your mortgage adviser will help you do the heavy lifting, making sure your application is completed correctly.<br/><br/>
            </p>
          </div>
    </div>
  );
};

export default SixStapes;
