import React from 'react';

const BenefitsOfOverpaymentsFAQ = () => ([
    {
      title: 'What are mortgage overpayments?',
      description: (
        <>
          A mortgage overpayment is an additional payment towards your mortgage on top of your monthly payments.<br/><br/>
          These can be made with spare cash either as a lump sum or as regular smaller overpayments.<br/><br/>
          Mortgage lenders typically allow overpaying around 10% of the annual mortgage balance before they charge an early repayment charge.
        </>
      ),
    },
    {
      title: 'Should I overpay my mortgage?',
      description: (
        <>
          Making an overpayment depends on your personal situation and financial circumstances. They can be a great way to save money on paying interest, get access to a better interest rate when remortgaging and become mortgage-free faster.<br/><br/>
          However, it's important to review that you can afford to make overpayments and have cash savings set aside in case of emergency.<br/><br/>
          When mortgage rates are high, or there are rising interest rates it's particularly a good time to make overpayments as you will be paying off more of the mortgage.<br/><br/>
          Even more, if interest rates are low on savings accounts you will be earning less interest from your cash savings.<br/><br/> However, it's a good idea to speak to a mortgage broker for advice and to review your living expenses to ensure that you can keep affording your monthly repayments on your mortgage.
        </>
      ),
    },
    {
      title: 'Does making overpayments reduce mortgage interest?',
      description: (
        <>
          Making mortgage overpayments can be a great way to reduce the amount you pay in interest over the term of your mortgage. The overpayment will be paid directly to pay off the interest rate of the mortgage.<br/><br/>
          Also, if you make overpayments when you mortgage is coming up to it's expiry it could give you access to a better mortgage rate on your next deal by reducing your loan-to-value.
        </>
      ),
    },
    {
      title: 'Can I make an overpayment on any mortgage deal?',
      description: (
        <>
          Most mortgage types allow for overpayments, but it's wise to confirm with your lender. Most mortgages allow a 10% overpayment allowance.<br/><br/>
          A repayment mortgage is where you pay back to capital and the interest at the same time. A fixed-rate and a variable-rate mortgage are types of repayment mortgages that usually allow for making overpayments but tend to come with early repayment charges so it's best to check the terms.
        </>
      ),
    },
    {
      title: 'Do I need to tell my mortgage lender when I start making overpayments?',
      description: (
        <>
          If you don’t contact your lender to update your overpayment preferences, you may not be saving as much as you think. Most lenders default to reducing your monthly payments, which means the overpayments won’t be going towards the capital. Call your lender to tell them you want your overpayments to reduce the capital and keep your monthly payments the same to make sure you're getting the full benefits.
        </>
      ),
    },
    {
      title: 'How does overpaying your mortgage affect my monthly payments?',
      description: (
        <>
          When you make overpayments, either in regular small payments or as a lump sum, your total mortgage balance decreases, which could lead to a decrease in the amount of interest you owe each month.<br/><br/>
          This reduction in interest could result in a smaller portion of your monthly payment going towards interest and a larger portion towards the principal balance.<br/><br/>
          However, your monthly payment might remain the same unless you specifically request a reduction from your lender, so it's important to contact your lender to update them on your overpayment preferences on your mortgage deal to make sure you're having the benefits of overpaying on your mortgage, such as access to a better interest rate on your next deal.

        </>
      ),
    },
    {
      title: 'Are there any potential drawbacks to mortgage overpayments?',
      description: (
        <>
          There are some things to take into consideration when making overpayments. Some mortgages might have early repayment charges or restrictions on overpayments, so it's important to check your mortgage terms before making extra payments.
          It can be difficult to get the money back once you've made the payment to your lender. It's wise to have an emergency fund aside in case of any unforeseen circumstances so you have access to this before making overpayments on your mortgage either as a lump sum or as regular small payments.

        </>
      ),
    },
    {
      title: 'Does making an overpayment reduce my mortgage balance?',
      description: (
        <>
          Overpaying on your mortgage can mean you paying off your total mortgage early as you can save money on the total amount of interest you pay and potentially clear your mortgage balance quicker.<br/><br/>
          Making overpayments on your mortgage can mean a better interest rate on your next mortgage borrowing, and so reduce the overall balance and means you could pay the mortgage early.
        </>
      ),
    },
    {
      title: 'Is it better to make an overpayment or put money in savings accounts?',
      description: (
        <>
          Overpaying your mortgage can be a great use of your spare cash and to become mortgage-free quicker. However, this depends on the financial markets, interest rates and your own personal circumstances.<br/><br/>
          It's important to pay down your credit card debts or larger loan debts first, as well as having a savings account with an emergency fund should there be any unforeseen circumstances. After that then it's a good time to review if you can afford to overpay your mortgage debt.
        </>
      ),
    },
    {
      title: 'Are mortgage overpayments suitable for everyone?',
      description: (
        <>
          Mortgage overpayments are a great option for those with extra funds and a desire to pay off their mortgage faster and reduce the mortgage term. However, individuals with high-interest debts or those who haven't established an emergency fund might have other financial priorities.<br/><br/>
          Remember, every individual's financial situation is unique, so it's important to consider your own goals, circumstances, and lender terms before deciding on overpaying your mortgage.
        </>
      ),
    },
    {
      title: 'Can I make overpayments with Sprive?',
      description: (
        <>
          You can use Sprive to make easy one-tap overpayments straight to your lender. You can use Sprive's autosaving AI and we'll set aside spare cash for you and then you can put this towards your mortgage in one tap. Or you can make one-off mortgage overpayments when you like.<br/><br/>
          You can use our mortgage overpayment calculator to see how much you could save in interest and years saved off your mortgage term.
        </>
      ),
    },
]);

  export default BenefitsOfOverpaymentsFAQ;
