import React from 'react';

const SelfEmployedFAQ = () => ([
    {
      title: 'How do lenders decide if I\'m self-employed for mortgage purposes?',
      description: (
        <>
          Most lenders consider a borrower to be self-employed if their earnings are above certain thresholds. Usually, you will be considered self-employed if you currently own a 20% share or more in a business which contributes the majority of your income
        </>
      ),
    },
    {
      title: 'How do lenders assess my income if I\'m self-employed?',
      description: (
        <>
          When applying for a self employed mortgage, like with a standard mortgage application, most lenders will check your income stability and your affordability of the mortgage will be checked.<br/><br/>
          Most mortgage providers will review your tax year overview, financial statements, bank statements, credit rating, and financial accounts to check that you have a reliable income to be able to afford your monthly repayments.
        </>
      ),
    },
    {
      title: 'Is it harder for self-employed people to remortgage?',
      description: (
        <>
         Getting a mortgage or remortgage can be quite a long and confusing process. Getting a mortgage when self employed can sometimes make it a little more difficult as there are more documents that you need to provide, like bank statements, credit score check, self assessment tax return.<br/><br/>
         However, if you have all of the right documents then it can be as simple as a standard remortgage. Speaking to a mortgage broker can make the process to get a mortgage a lot easier and quicker as they can advise on the lending criteria for each mortgage provider for you.
        </>
      ),
    },
    {
      title: 'Will I pay higher mortgage rates on a self-employed mortgage?',
      description: (
        <>
          When you apply for a mortgage, if you can provide proof and stability of income to ensure you can prove that you can afford the monthly repayments, you should qualify for the same mortgage rates as someone who is in a permanent, full-time role.
        </>
      ),
    },
    {
      title: 'What type of mortgage should I get if I am self-employed?',
      description: (
        <>
        If you are a self-employed mortgage applicant, the type of mortgage you should get depends on various factors.<br /><br />
        The things that are important to consider are your income stability, whether are you on a joint mortgage and your long-term goals.<br /><br />
        It's important to review all of the types of mortgage deals available from different mortgage lenders to understand what suits your personal circumstances and preferences before you get a mortgage.
        </>
      ),
    },
    {
      title: 'Should I use a mortgage broker for my self-employed remortgage?',
      description: (
        <>
        Using a mortgage broker can be a great idea for self-employed borrowers as they have the expertise to make sure you're providing the right documents like bank statements and proof of a good credit score to give you the best chance of getting your mortgage accepted.<br /><br />
        It's wise to make sure they have access to the whole market of mortgage lenders so you have access to more mortgage deals. They can also advise you with mortgage lenders who specialise in dealing with complex and fluctuating income structures for self-employed people, and offer guidance throughout the process.
        </>
      ),
    },
    {
      title: 'Is it easier to remortgage with the same mortgage lender if I am self-employed?',
      description: (
        <>
        Remortgaging with the same lender can make things quicker and easier as you require fewer documents and sometimes don't require an affordability check. The self-employment mortgage requirements for switching with the same or different lender don't change much if you're self-employed or not.<br /><br />
        However, it's worth noting remortgaging on product transfer will give you access to fewer mortgage deals as you won't have access to the whole mortgage market.
        </>
      ),
    },
    {
      title: 'Can Sprive help me remortgage if I\'m self-employed?',
      description: (
        <>
        Definitely! If you're looking at your options to remortgage, you can speak to a vetted mortgage adviser through Sprive who can help you find your next mortgage deal and apply for a mortgage that's right for you.
        </>
      ),
    },
  ]);

export default SelfEmployedFAQ;
