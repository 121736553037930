import { useLocation } from '@reach/router';
import { Link } from 'gatsby';
import React, { useState, useEffect } from 'react';
import DownloadAppSection from '../../components/CommonSections/DownloadAppSection';
import FAQSection from '../../components/FAQSection';
import options from '../../constants/Options';
import Advisor from './Advisor';
import Affordability from './Affordability';
import Amortisation from './Amortisation';
import BenefitsOfOverpayments from './BenefitsOfOverpayments';
import FixedVariable from './FixedVariable';
import GreenMortgages from './GreenMortgages';
import ImpactTheCost from './ImpactTheCost';
import IncreaseChances from './IncreaseChances';
import KeyRemortgage from './KeyRemortgage';
import LTV from './LTV';
import LockADeal from './LockADeal';
import MakingOverpayments from './MakingOverpayments';
import MortgageConsider from './MortgageConsider';
import MortgageEarly from './MortgageEarly';
import Overpayments from './Overpayments';
import OverpaymentsPreferences from './OverpaymentsPreferences';
import Remortgage from './Remortgage';
import RepaymentCharge from './RepaymentCharge';
import RepaymentMortgages from './RepaymentMortgages';
import SameLender from './SameLender';
import SelfEmployed from './SelfEmployed';
import SixStapes from './SixStapes';
import TimeToSwitch from './TimeToSwitch';
import AdvisorFAQ from './faq/AdvisorFAQ';
import AffordabilityFAQ from './faq/AffordabilityFAQ';
import AmortisationFAQ from './faq/AmortisationFAQ';
import BenefitsOfOverpaymentsFAQ from './faq/BenefitsOfOverpaymentsFAQ';
import FixedVariableFAQ from './faq/FixedVariableFAQ';
import GreenMortgagesFAQ from './faq/GreenMortgagesFAQ';
import ImpactTheCostFAQ from './faq/ImpactTheCostFAQ';
import IncreaseChancesFAQ from './faq/IncreaseChancesFAQ';
import KeyRemortgageFAQ from './faq/KeyRemortgageFAQ';
import LTVFAQ from './faq/LTVFAQ';
import LockADealFAQ from './faq/LockADealFAQ';
import MakingOverpaymentsFAQ from './faq/MakingOverpaymentsFAQ';
import MortagageConsiderFAQ from './faq/MortagageConsiderFAQ';
import MortgageEarlyFAQ from './faq/MortgageEarlyFAQ';
import OverpaymentsFAQ from './faq/OverpaymentsFAQ';
import OverpaymentsPreferencesFAQ from './faq/OverpaymentsPreferencesFAQ';
import RemortgageFAQ from './faq/RemortgageFAQ';
import RepaymentChargeFAQ from './faq/RepaymentChargeFAQ';
import RepaymentMortgagesFAQ from './faq/RepaymentMortgagesFAQ';
import SameLenderFAQ from './faq/SameLenderFAQ';
import SelfEmployedFAQ from './faq/SelfEmployedFAQ';
import SixStapesFAQ from './faq/SixStapesFAQ';
import TimeToSwitchFAQ from './faq/TimeToSwitchFAQ';
import useWindow from '../../hooks/useWindow';

function SingleAcademo() {
  // Get the location object using useLocation hook
  const { isMobile } = useWindow();
  const location = useLocation();
  const currentPath = location.pathname;
  const allOptions = options;

  const pathhhh = currentPath.split('/')[2];
  const selectedItem = allOptions.find((data) => data.path === pathhhh);

  const index = allOptions.findIndex((i) => i.path === pathhhh);

  const [hasMounted, setHasMounted] = useState(false);
  
  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    return null;
  }


  return (
    <div className="academy_details">
      <div className="container">
        <div>
          <h1
            className="mb-5 academy_details_title"
          >
            {selectedItem?.title.replace(/^\d+\.\s/, '')}
          </h1>
          <div>
            <iframe
              width="100%"
              height="315"
              src={`https://www.youtube.com/embed/${selectedItem?.iframe}`}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
          <div
            style={{ gap: '20px' }}
            className="d-flex flex-column flex-md-row mb-5 align-items-center justify-content-center"
          >
            {index !== 0 && (
              <Link to={index === 0 ? null : `/academy/${allOptions[index - 1]?.path}`}>
                <button className="blank_pink_btn">Previous episode</button>
              </Link>
            )}
            {index + 1 !== allOptions.length && (
              <Link
                to={
                  index + 1 === allOptions.length ? null : `/academy/${allOptions[index + 1]?.path}`
                }
              >
                <button className="pink_btn">Next episode</button>
              </Link>
            )}
          </div>
          {
            {
              'amortisation': <Amortisation/>,
              'overpayments': <Overpayments/>,
              'overpaymentsbenefits': <BenefitsOfOverpayments/>,
              'makingOverpayments': <MakingOverpayments/>,
              'overpaymentspreferences': <OverpaymentsPreferences/>,
              'erc': <RepaymentCharge />,
              'affordability': <Affordability />,
              'ltv': <LTV />,
              'mortgagetips': <MortgageEarly />,
              'betterdeal': <IncreaseChances />,
              'sixstepsremortaging': <SixStapes />,
              'mortgagecost': <Remortgage />,
              'mortgageterm': <ImpactTheCost />,
              'benefitsofadviser': <Advisor />,
              'existingornewlender': <SameLender />,
              'remortgagetips': <KeyRemortgage />,
              'selfemployed': <SelfEmployed />,
              'timetoremortgage': <TimeToSwitch />,
              'mortgagelength': <LockADeal />,
              'mortgagedeals': <MortgageConsider />,
              'interestvsrepayment': <RepaymentMortgages />,
              'fixedvsvariable': <FixedVariable />,
              'greenmortgages': <GreenMortgages />,
            }[selectedItem?.path]
          }
        </div>
      </div>

      {
        {
          'amortisation':
            <FAQSection
              FAQItems={AmortisationFAQ}
              title={<>Want to learn more about amortisation? {isMobile || <br/> } Read our FAQs</>}
            />,
          'overpayments':
            <FAQSection
              FAQItems={OverpaymentsFAQ}
              title={<>Want to learn more about overpaying your mortgage?{isMobile || <br/> } Read our FAQs</>}
            />,
          'overpaymentsbenefits':
              <FAQSection
                FAQItems={BenefitsOfOverpaymentsFAQ}
                title={<>Want to learn more about mortgage overpayments?{isMobile || <br/> } Read our FAQs</>}
            />,
          'overpaymentspreferences':
            <FAQSection
              FAQItems={OverpaymentsPreferencesFAQ}
                title={<>Want to learn more about overpaying your mortgage?{isMobile || <br/> } Read our FAQs</>}
              />,
          'erc':
            <FAQSection
              FAQItems={RepaymentChargeFAQ}
              title={<>Want to learn more about early repayment charges?{isMobile || <br/> } Read our FAQs</>}
            />,
          'affordability':
            <FAQSection
              FAQItems={AffordabilityFAQ}
              title={<>Want to learn more about mortgage {isMobile || <br />} checks and affordability?{isMobile || <br/> } Read our FAQs</>}
            />,
          'ltv':
            <FAQSection
              FAQItems={LTVFAQ}
              title={<>Want to learn more about loan to value (LTV)?{isMobile || <br/> } Read our FAQs</>}
            />,
          'mortgagetips':
            <FAQSection
              FAQItems={MortgageEarlyFAQ}
              title={<>Want to learn more about paying off your mortgage?{isMobile || <br/> } Read our FAQs</>}
            />,
          'betterdeal':
            <FAQSection
              FAQItems={IncreaseChancesFAQ}
              title={<>Want to learn more about remortgaging?{isMobile || <br/> } Read our FAQs</>}
            />,
          'makingOverpayments':
            <FAQSection
              FAQItems={MakingOverpaymentsFAQ}
              title={<>Want to learn more about mortgage overpayments?{isMobile || <br/> } Read our FAQs</>}
            />,
          'sixstepsremortaging':
            <FAQSection
              FAQItems={SixStapesFAQ}
              title={<>Want to learn more about remortgaging?{isMobile || <br/> } Read our FAQs</>}
            />,
          'mortgagecost':
            <FAQSection
              FAQItems={RemortgageFAQ}
              title={<>Want to learn more about the cost to remortgage?{isMobile || <br/> } Read our FAQs</>}
            />,
          'mortgageterm':
            <FAQSection
              FAQItems={ImpactTheCostFAQ}
              title={<>Want to learn more about the term of a mortgage? {isMobile || <br/> } Read our FAQs</>}
            />,
          'benefitsofadviser':
            <FAQSection
              FAQItems={AdvisorFAQ}
              title={<>Want to learn more about the benefits {isMobile || <br />}of mortgage brokers?{isMobile || <br/> } Read our FAQs</>}
            />,
          'existingornewlender':
            <FAQSection
              FAQItems={SameLenderFAQ}
              title={<>Want to learn more about product transfer mortgages or remortgaging to a new lender?{isMobile || <br/> } Read our FAQs</>}
            />,
          'remortgagetips':
            <FAQSection
              FAQItems={KeyRemortgageFAQ}
              title={<>Want to learn more about remortgaging?{isMobile || <br/> } Read our FAQs</>}
            />,
          'selfemployed':
            <FAQSection
              FAQItems={SelfEmployedFAQ}
              title={<>Want to learn more about a self{isMobile && <br />} employed mortgage?{isMobile || <br/> } Read our FAQs</>}
            />,
          'timetoremortgage':
            <FAQSection
              FAQItems={TimeToSwitchFAQ}
              title={<>Want to learn more about remortgaging?{isMobile || <br/> } Read our FAQs</>}
            />,
          'mortgagelength':
            <FAQSection
              FAQItems={LockADealFAQ}
              title={<>Want to learn more about mortgage terms?{isMobile || <br/> } Read our FAQs</>}
            />,
          'mortgagedeals':
            <FAQSection
              FAQItems={MortagageConsiderFAQ}
              title={<>Want to learn more about types of mortgage deals?{isMobile || <br/> } Read our FAQs</>}
            />,
          'interestvsrepayment':
            <FAQSection
              FAQItems={RepaymentMortgagesFAQ}
              title={<>Want to learn more about interest {isMobile || <br />}and repayment mortgages?{isMobile || <br/> } Read our FAQs</>}
            />,
          'fixedvsvariable':
            <FAQSection
              FAQItems={FixedVariableFAQ}
              title={<>Want to learn more about fixed-rate mortgages or variable-rate mortgages? {isMobile || <br/> } Read our FAQs</>}
            />,
          'greenmortgages':
            <FAQSection
              FAQItems={GreenMortgagesFAQ}
              title={<>Want to learn more about green mortgages? {isMobile || <br/> } Read our FAQs</>}
            />,
        }[selectedItem?.path]
      }
      <DownloadAppSection />
    </div>
  );
}

export default SingleAcademo;
