import React from 'react';

const RepaymentMortgagesFAQ = () => ([
    {
      title: 'What is an interest-only mortgage? What are the pros and cons?',
      description: (
        <>
        Interest-only mortgages are where your monthly repayments are only going towards paying off the mortgage interest, and not towards the capital. So with each payment, you're not reducing the amount you owe. They tend to be offered on both fixed-rate mortgages or variable mortgages.<br /><br />
        Although these mortgages seem more affordable, they are more expensive in the long term as you're not chipping away the total cost of the mortgage with each monthly payment.<br /><br />
        The reason for this type of mortgage is that you can keep your monthly cost to a minimum.<br /><br />
        The biggest drawback of an interest-only mortgage is that you don’t pay off the loan as you go.<br /><br />
        You will also be repaying a lot more in the total amount to your mortgage in an interest-only mortgage as you are not going to pay interest on each payment.
        </>
      ),
    },
    {
      title: 'What is a repayment mortgage?',
      description: (
        <>
          A repayment mortgage is when your monthly mortgage repayments go towards two pots, one towards paying off the amount you’ve borrowed (the capital) and the other towards paying off the interest.<br/><br/>
          Repayment mortgages are the most common type of mortgage as you're paying off the mortgage term as you go, unlike an interest-only mortgage.<br /><br />
          Fixed-rate mortgages, variable mortgages, standard variable-rate mortgages, tracker mortgages and discount mortgages are all types of repayment mortgages.
        </>
      ),
    },
    {
      title: 'What is a variable rate mortgage? What are the pros and cons?',
      description: (
        <>
          A variable-rate mortgage is where the interest rate, and monthly repayments, can increase or decrease during the term of your mortgage, usually following the Bank of England base rate.
        </>
      ),
    },
    {
      title: 'What is a fixed-rate mortgage? What are the pros and cons?',
      description: (
        <>
          A fixed-rate mortgage is a mortgage where the monthly repayments stay the same during the whole term of the mortgage deal.<br/><br/>
          Fixed-rate mortgages are a good idea if you want the security of knowing what your monthly repayments are going to be so you can plan to budget.<br /><br />
          The disadvantage of a fixed-rate mortgage is that if interest rates fall you won't benefit from lower mortgage rates as your interest on your mortgage deal will stay the same.
        </>
      ),
    },
    {
      title: 'What are tracker mortgages? What are the pros and cons?',
      description: (
        <>
          Tracker mortgages are the type of mortgage where the interest rate varies during the course of the term according to the Bank of England's base rate.<br/><br/>
          A tracker mortgage is a good idea if you predict that interest rates fall in the near future during the course of your mortgage term because as interest rates fall, so will the cost of your mortgage.<br /><br />
          The disadvantage of a tracker mortgage is that it can be difficult to budget and you don't have the financial security of knowing what your monthly repayments are going to be for the whole course of your mortgage deal. If the Bank of England's base rate changes then you are going to see changes to your mortgage monthly payments.
        </>
      ),
    },
    {
      title: 'What type of mortgage deal is right for me?',
      description: (
        <>
          The type of mortgage depends on your personal circumstances and preferences so it's best to speak to a mortgage broker to understand what the best option is for you.<br/><br/>
          As a guideline, usually, if you are looking for security in your monthly payments fixed-rate mortgages are a good option, whereas if you're looking for more flexibility then a variable-rate mortgage could be more suitable.
        </>
      ),
    },
  ]);

export default RepaymentMortgagesFAQ;
