import React from 'react';

const MakingOverpaymentsFAQ = () => ([
    {
      title: 'What are mortgage overpayments?',
      description: (
        <>
          A mortgage overpayment is an additional payment towards your mortgage on top of your monthly payments. <br /><br />
          These can be made with spare cash either as a lump sum or as regular smaller overpayments.<br /><br />
          Mortgage lenders typically allow overpaying around 10% of the annual mortgage balance before they charge an early repayment charge.
        </>
      ),
    },
    {
      title: 'Can I make mortgage overpayments on any type of mortgage?',
      description: (
        <>
          Most mortgage types allow for overpayments, but it's wise to confirm with your lender. Most mortgages allow a 10% overpayment allowance.<br/><br/>
          A repayment mortgage is where you pay back to capital and the interest at the same time. A fixed-rate and a variable-rate mortgage are types of repayment mortgage and usually allow for making overpayments but tend to come with early repayment charges so it's best to check the terms.
        </>
      ),
    },
    {
      title: 'How does overpaying your mortgage affect my monthly payments?',
      description: (
        <>
          When you make overpayments, either in regular small payments or as a lump sum, your total mortgage balance decreases, which could lead to a decrease in the amount of interest you owe each month. This reduction in interest could result in a smaller portion of your monthly payment going towards interest and a larger portion towards the principal balance.<br/><br/>
          However, your monthly payment might remain the same unless you specifically request a reduction from your lender, so it's important to contact your lender to update them on your overpayment preferences on your mortgage deal to make sure you're having the benefits of overpaying on your mortgage.
        </>
      ),
    },
    {
      title: 'Are mortgage overpayments suitable for everyone?',
      description: (
        <>
          Mortgage overpayments are a great option for those with extra funds and a desire to pay off their mortgage faster and reduce the mortgage term. However, individuals with high-interest debts or those who haven't established an emergency fund might have other financial priorities.<br/><br/>
          Remember, every individual's financial situation is unique, so it's important to consider your own goals, circumstances, and lender terms before deciding on overpaying your mortgage.
        </>
      ),
    },
    {
      title: 'Can I choose how often I make mortgage overpayments?',
      description: (
        <>
        Depending on your mortgage terms, you might have the flexibility to choose when and how often you make overpayments. Some lenders allow for one-time lump-sum payments, while others let you increase your monthly mortgage payment amount.
        </>
      ),
    },
    {
      title: 'Is there a minimum amount I need to overpay to see an impact?',
      description: (
        <>
        Lump sum overpayments can lead to quick results in terms of interest savings and mortgage payoff acceleration.<br /><br />
        There isn't usually a minimum amount you can make as an overpayment. Even small overpayments can have a positive impact over time.<br /><br />
        You can use a mortgage overpayment calculator to see how much you could save in interest and years saved off your mortgage term.
        </>
      ),
    },
  ]);

export default MakingOverpaymentsFAQ;
