import React from 'react';

const MakingOverpayments = () => {
  return (
    <div>
          <div className="takeaways">
            <h2>Key Takeaways</h2>
            <ul>
              <li>Making mortgage overpayments reduces the amount you owe to the bank, saving you money on your mortgage. It can reduce your loan-to-value and give you access to better rates.</li>
              <li>
              Once you’ve made the overpayment, it can be difficult to get that money back
              </li>
              <li>
              It’s a good idea to have an emergency fund of six months of mortgage payments in case of unforeseen emergencies
              </li>
              <li>
              Lenders won’t always automatically allow you to pay less in the future just because you’ve made an overpayment
              </li>
              <li>
              You can usually make mortgage overpayments of up to 10% of the balance each year before experiencing an early repayment charge
              </li>
            </ul>
          </div>
          <div className="transcript">
            <h2>Transcript</h2>
            <p>
              Things to be aware of when making overpayments.<br/><br/>
              Making overpayments to your mortgage can be a great way to ensure that when you come to remortgage, you can benefit from the lower loan amount, as it may give you access to cheaper rates, as your loan to value is less, you will also pay off your mortgage quicker, which will save you interest.<br/><br/>
              Before making any overpayments, however, there are a few things to be aware of. <br/><br/>
              The biggest thing to be aware of when making overpayments is once you've parted with the money to make that overpayment, it can be difficult to get it back if you suddenly realise you need to access that cash.<br/><br/>
              To get the money back from the lender, you may need to remortgage to release the equity or apply to your lender for a further advance. You should also be wary about not emptying all your savings to make an overpayment. As a general rule, it's advisable to have at least six months of mortgage payments in an easy-access account, just in case you lost your job or had other unforeseen emergencies. It's also important to note that just because you've made an overpayment lenders won't necessarily automatically allow you to pay less than your contractual payment in the future.<br/><br/>
              Do also bear in mind how much your lender will allow you to overpay before charging you an early repayment charge. Most fixed-rate mortgage products allow you to overpay at least 10 or 20% of the mortgage per year without charging you any early repayment charge. But best to check the conditions of your mortgage offer first.<br/><br/>
            </p>
          </div>
    </div>
  );
};

export default MakingOverpayments;
