import React from 'react';

const Affordability = () => {
  return (
    <div>
          <div className="takeaways">
            <h2>Key Takeaways</h2>
            <ul>
              <li>An affordability mortgage check will be run by the lender to determine how much you can borrow</li>
              <li>
                The key things the lender will look at are your credit rating, your income and your property value
              </li>
              <li>
              Staying with the same lender sometimes means you won’t need an affordability check
              </li>
            </ul>
          </div><br/>
          <div className="transcript">
            <h2>Transcript</h2>
            <p>
              Affordability - What does your lender look for when deciding on your remortgage?<br/><br/><br/>
              Like when you first take out your mortgage, an affordability check will be run by the lender to determine how much you can borrow.<br /><br/>
              Some of the things that the lender will look out for to ensure you can pass affordability is to look at your credit rating and see if it has changed since the last time you took out your mortgage or if there's been any change in income which affects your ability to make your monthly payments.<br /><br/>
              They will also look into your property value.<br /><br/>
              Is the property value now less than your outstanding mortgage? If so, this can make it difficult to remortgage. This is known as negative equity.<br /><br/>
              If you're concerned you may not be able to remortgage due to affordability checks, there are some things you're able to do, such as considering staying with your same lender where they often don't need to run affordability checks, although you won't have access to the best deals or as much flexibility.<br /><br/>
              Also, make sure you get expert advice and speak to a mortgage advisor to see what your options are.<br /><br/>
            </p>
          </div>
    </div>
  );
};

export default Affordability;
