import React from 'react';

const IncreaseChances = () => {
  return (
    <div>
          <div className="takeaways">
            <h2>Key Takeaways</h2>
            <ul>
              <li>Check your credit score is up-to-date and accurate</li>
              <li>
              Check if you put down a larger deposit to get access to better interest rates
              </li>
              <li>
              Speak to a mortgage broker, be honest with your mortgage application and have all documents ready
              </li>
              <li>
              Avoid taking on any more loans in the run-up to remortgaging
              </li>
            </ul>
          </div>
          <div className="transcript">
            <h2>Transcript</h2>
            <p>
              Increase your chances of getting a better remortgage deal.<br/><br/>
              The mortgage market can be overwhelming and that's no different when it comes to remortgaging<br></br>
              There are a few things that you can do to make sure you're in good shape to get the best possible deal when it's time for a new deal.<br></br><br/>
              Number one, how's your credit score? Your credit score can affect your ability to get a good mortgage deal. Make sure your credit score is up to date and accurate before applying for a mortgage.<br /><br/>
              Number two, are you able to put down a larger deposit? This can help you access better deals with lower interest rates.<br /><br/>
              Number three, speak to a mortgage broker. They provide access to a wider range of deals, exclusive deals, and also offer expert advice.<br /><br/>
              Number four, shop around and compare different remortgage deals from different lenders, rather than just accepting the first offer you receive.<br /><br/>
              Number five, be honest and accurate when providing the information to your lender, as any discrepancies or inaccuracies could affect your remortgage application.<br /><br/>
              Number six, have all necessary documentation ready, such as proof of income, bank statements and ID to speed up the remortgaging process.<br /><br/>
              Number seven, avoid taking on any new credit cards or loans in the months leading up to your remortgage application, as this can negatively impact your credit score and remortgage eligibility.<br /><br/>
              Number eight, make an informed choice. This can help you secure the best remortgage deal for your needs.<br /><br/>
            </p>
          </div>
    </div>
  );
};

export default IncreaseChances;
