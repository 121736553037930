import React from 'react';

const LTVFAQ = () => ([
    {
      title: 'What is Loan-to-Value (LTV)?',
      description: (
        <>
          LTV or loan-to-value is the mortgage expressed as a percentage of the property value. This means how much of the property you own compared to how much you owe on the mortgage loan.
        </>
      ),
    },
    {
      title: 'Why is the LTV important when taking out a mortgage?',
      description: (
        <>
          LTV is crucial for lenders as it assesses the risk associated with a mortgage.<br/><br/>
          The higher the LTV ratio means the borrower is taking on more risk as they will be taking out more against the value of the property. A lower ratio tends to reflect a larger deposit, and so less of a risk for the lender.
        </>
      ),
    },
    {
      title: 'How does the LTV ratio impact mortgage approval?',
      description: (
        <>
          Lenders often have maximum LTV limits for different mortgages to protect themselves from the borrowers being unable to pay back the mortgage.<br/><br/>
          A higher LTV ratio often means higher interest rates in comparison to a lower LTV. This is why it's often advised to make overpayments or larger lump sums on the outstanding mortgage when it comes to remortgaging.<br />
          Having a lower LTV ratio can provide leverage when negotiating mortgage terms with lenders as you already have a larger homeownership amount so this can lead to better interest rates.
        </>
      ),
    },
    {
      title: 'Is there a specific LTV ratio I should aim for when remortgaging?',
      description: (
        <>
          While it varies by lender and loan type, aiming for an LTV ratio below 80% is considered a good ltv and can be beneficial when remortgaging.<br/><br/>
          This can potentially help you secure better rates and terms.
        </>
      ),
    },
    {
      title: 'Why do I need to be aware of my loan-to-value ratio?',
      description: (
        <>
          Mortgage lenders review your loan to value ratio when you take out a new mortgage or a remortgage and they consider it when deciding if they accept your mortgage application.<br/><br/>
          The lower your ltv the better as you will get access to better interest rates and mortgage deals.
        </>
      ),
    },
    {
      title: 'Can remortgaging help me lower my LTV ratio?',
      description: (
        <>
          Yes, remortgaging with a larger down payment or a lower loan amount can help you lower your LTV ratio. However, keep in mind that there might be costs associated with remortgaging.
        </>
      ),
    },
    {
      title: 'How do I lower my loan-to-value ratio?',
      description: (
        <>
          Lowering your Loan-to-Value (LTV) ratio involves reducing the amount you owe on your mortgage relative to your home's value. There are a few things you can do to help improve this. <br />
          <ol>
            <li>Make overpayment on your mortgage to reduce the loan balance faster, which directly lowers your LTV ratio over time.</li>
            <li>Put down a larger deposit or lump sum when you take out the mortgage which will give you a lower initial LTV ratio.</li>
            <li>Avoid taking out additional loads, especially in the lead-up to your remortgage or when you're taking out a mortgage</li>
            <li>Reducing other debts can improve your debt-to-income ratio, potentially making it easier to qualify for a lower LTV refinance.</li>
            <li>Improve Your Credit Score</li>
          </ol>
        </>
      ),
    },
  ]);

export default LTVFAQ;
