import React from 'react';

const Amortisation = () => {
  return (
    <div>
          <div className="takeaways">
            <h2>Key Takeaways</h2>
            <ul>
              <li>Amortisation refers to how the mortgage is paid off during its lifetime</li>
              <li>
              Earlier on in the mortgage, you pay more in interest on your loan payments compared to later in the term where it then goes towards paying off the capital
              </li>
              <li>
              The capital refers to the amount you've borrowed
              </li>
              <li>
              The structure of monthly payments going towards two ‘pots’; the capital and the interest, is what is called amortisation
              </li>
            </ul>
          </div>
          <div className="transcript">
            <h2>Transcript</h2>
            <p>
              What is amortisation and why it matters?<br/><br/>
              Amortisation is the financial term for how a mortgage is paid off during its lifetime.<br /><br/>
              Understanding when interest is paid across the overall term of the mortgage is crucial if you want to save money on your mortgage.<br /><br/>
              In the earlier years of your mortgage, you'll find yourself paying your lender much more interest compared to later in the term when you start paying back more of the original money you borrowed.<br /><br/>
              At first, typically the majority of a mortgage payment that is set up on a conventional capital and interest arrangement is more top-heavy on interest than it is the capital.<br /><br/>
              The capital, by the way, is the amount your mortgage is coming down by each time you make a monthly payment. As the mortgage goes on, the amount you pay per month towards the capital increases and the amount towards interest decreases.<br /><br/>
              There will typically be a payment schedule on official mortgage documents which will highlight these amounts. This structure of a monthly payment goes towards two separate pots. One of interest, one of the capital. That's what's known as amortisation.<br /><br/>
            </p>
          </div>
    </div>
  );
};

export default Amortisation;
