import React from 'react';

const Overpayments = () => {
  return (
    <div>
          <div className="takeaways">
            <h2>Key Takeaways</h2>
            <ul>
              <li>Mortgage overpayments are additional payments made on top of your monthly mortgage payments during the mortgage term</li>
              <li>
                You can usually overpay your mortgage by up to 10% of the mortgage balance each year{' '}
              </li>
              <li>
               Making overpayments on top of your monthly payment, chips away at the mortgage and reduces the capital that you owe
              </li>
              <li>
                Making overpayments helps you save money on your mortgage by chipping away at the interest on your mortgage
              </li>
            </ul>
          </div>
          <div className="transcript">
            <h2>Transcript</h2>
            <p>
              What are mortgage overpayments?<br/><br/>
              Mortgage overpayments are additional payments on top of your monthly mortgage commitments.<br/><br/>
              The majority of banks and lenders will typically allow you to overpay 10% of the balance each year.<br/><br/>
              So when taking out your mortgage, it's important to consider do you want to make more overpayments than 10%. Or even do you want the option to make unlimited overpayments?<br/><br/>
              By making overpayments on your mortgage, you can reduce your mortgage balance and chip away at the capital you owe.<br/><br/>
              You can either make regular small overpayments or make one-off payments when you have some spare money.<br/><br/>
              The benefit of making an overpayment is that you will be directly reducing the balance and retaining more equity in your property.<br/><br/>
              For example, take a £300,000 mortgage over 25 years at a 5% interest rate.<br/><br/>
              If you overpay by an extra three pounds a day, you could save around £23,000 in interest alone and pay off your mortgage over two years earlier.<br/><br/>
              As you can see, you'll be surprised by how much of an impact you can make by making small, regular overpayments.<br/><br/>
              So this could be something worth looking into if paying off your mortgage faster is a priority for you.<br/><br/>
            </p>
          </div>
    </div>
  );
};

export default Overpayments;
