import React from 'react';

const AffordabilityFAQ = () => ([
    {
      title: 'What is a mortgage affordability check?',
      description: (
        <>
          An affordability check refers to what mortgage lenders look for when assessing whether you can afford the mortgage agreement. Lenders look at your ability to comfortably manage mortgage payments along with other financial obligations based on your income, expenses, and financial stability. <br />
          A mortgage lender will have their own mortgage application process to check if you can afford the mortgage.
        </>
      ),
    },
    {
      title: 'How do lenders determine if you can afford a mortgage?',
      description: (
        <>
          When applying for a mortgage or a remortgage, lenders assess your affordability based on multiple factors to make sure that you can afford the monthly payments.<br/><br/>
          Lenders will look at your income, your outgoings and monthly expenses, any debts, and payments of those debts, and your credit report, so it's important to have these documents ready to make sure the process is as quick as possible.
        </>
      ),
    },
    {
      title: 'What documents do I need to provide?',
      description: (
        <>
          When you apply for a mortgage, mortgage brokers can help make sure you have all of the right documents to make a formal mortgage offer and go through the affordability process.<br/><br/>
          You will usually need to provide your ID, proof of income and tax statements.
        </>
      ),
    },
    {
      title: 'How does my credit score affect mortgage affordability?',
      description: (
        <>
         Your credit score is one of the most important contributions when applying for a mortgage and determining your mortgage approval.<br/><br/>
         The higher your credit score, usually means access to better mortgage rates and terms, as it shows responsible financial management and reduces the risk to the lender. <br />
         It's wise in the lead-up to your remortgage or going through the mortgage application process that you check your credit score to see if you can make any improvements.
        </>
      ),
    },
    {
      title: 'How can you improve your credit score?',
      description: (
        <>
         Reviewing your credit score is crucial when it comes to your time to the mortgage application process for a new mortgage or a remortgage. You can improve your credit score by:<br/><br/>
         <ol>
          <li>Limit your credit applications.</li>
          <li>Close any unused credit accounts</li>
          <li>Keep using your credit card and pay it off each month.</li>
          <li>Review any joint accounts and close them if necessary.</li>
          <li>Register to vote.</li>
         </ol>
         <br />
         The higher your credit score, usually means access to better mortgage rates and terms, as it shows responsible financial management and reduces the risk to the lender. <br />
         You can read more about your credit score <a href="https://sprive.com/blog/why-your-credit-score-matters-when-it-comes-to-mortgages/">here</a>.
        </>
      ),
    },
    {
      title: 'How can I estimate my mortgage affordability before approaching a lender?',
      description: (
        <>
         Online mortgage calculators are available to estimate affordability based on your income, expenses, and down payment. However, it's wise to speak to a mortgage broker for advice who can help you make the right decision.
        </>
      ),
    },
    {
      title: 'Could I fail the affordability check on a formal mortgage application?',
      description: (
        <>
         There may be a circumstance where your mortgage application is declined by mortgage lenders. This isn't uncommon, but it's important to know the reasons why your application hasn't been accepted by the mortgage provider. <br />
         Often it can be down to your credit score, credit repayment history, income or changes in income, bank statements and the property price.<br />
         Most lenders will review your bank statements, and check that you have a positive credit history to ensure that you can afford to pay back the monthly mortgage repayments and that you are applying for the right mortgage deal.<br />
        </>
      ),
    },
    {
      title: 'What happens if I fail the affordability check on my mortgage application?',
      description: (
        <>
         Not being accepted for the mortgage application process isn't uncommon and doesn't mean you won't be accepted for another mortgage. <br />
         Check that you've done everything that you can do to improve your affordability rating, like making any improvements to your credit rating and taking a look at your bank statement to see if you're spending too much money each month.<br />
         It's a good idea to speak to a mortgage broker to determine what you can afford first before applying for a mortgage and see if you need to look at a smaller mortgage amount.
        </>
      ),
    },
  ]);

export default AffordabilityFAQ;
