import React from 'react';

const SameLender = () => {
  return (
    <div>
          <div className="takeaways">
            <h2>Key Takeaways</h2>
            <ul>
              <li>The key benefits of remortgaging with the same lender (product transfer mortgage) are that it can be quicker, involve less paperwork and you may not need an affordability check</li>
              <li>
              The benefits of remortgaging with a new lender is you may get access to better deals and can change the term of your mortgage deal
              </li>
            </ul>
          </div>
          <div className="transcript">
            <h2>Transcript</h2>
            <p>
              Should I switch mortgages with my existing lender or remortgage?<br/><br/>
              Pros for switching. It's an easier process. The lender will not ask for proof of income or bank statements or require a new valuation to be carried out on the property. They also may not need to do a credit search.<br /><br />
              Speed. Because the lender already knows you an evaluation isn't needed. It can be done in a matter of days.<br /><br />
              Pros for Remortgaging. Better deals. You can search the market for better deals which may have come out since you last took a mortgage. Benefit from an increase in property value.<br /><br />
              If your property has gone up in value, better deals may now be available.<br /><br />
              You can change the term of your mortgage. You have the flexibility to change the term of your loan if your circumstances have now changed. Remortgaging with a new lender opens up more options, but switching is quicker and requires little to no documentation.<br /><br />
            </p>
          </div>
    </div>
  );
};

export default SameLender;
