import React from 'react';

const TimeToSwitch = () => {
  return (
    <div>
          <div className="takeaways">
            <h2>Key Takeaways</h2>
            <ul>
              <li>When your deal expires, you will be moved to your lender's standard variable rate (SVR) which is often a lot more expensive</li>
              <li>
              You can start looking for a new deal six months before your deal expires
              </li>
              <li>
              You can remortgage early but you will likely incur an early repayment charge. Sometimes you can benefit from paying the fee and remortgaging for a better deal
              </li>
              <li>
              You may want to remortgage to release cash, where you refinance to gain access to equity in your property.
              </li>
            </ul>
          </div>
          <div className="transcript">
            <h2>Transcript</h2>
            <p>
            When is the right time to switch?<br/><br/>
            Once a mortgage deal reaches the point that is coming to an end, the lender will move you to a Standard Variable Rate, otherwise known as SVR, which is likely going to be more expensive compared to other deals that are available to you.<br /><br />
            You can start looking at deals six months prior to when your deal period is ending. It is possible that while you are in your deal period that you could benefit from switching to a new deal by paying lower interest and saving money after taking into consideration any early repayment charges.<br /><br />
            This especially may be possible if you drop below a lender's loan to value threshold. This refers to the amount you borrow versus the value of your home. Typical lender thresholds are 90, 85, 80, 75 and 60%. As part of this process, switching to a new deal can be done on a like-for-like basis, which means you borrow the same amount again that you owe on the day your deal comes to an end.<br /><br />
            Or you can potentially look to release cash. Releasing cash is an exercise whereby you refinance to a new deal, increase the amount of borrowing, and take this out. Lenders can consider releasing cash for a range of reasons, including home improvements, and replenishing savings. If, for example, you've done some work to your house and want the money back, debt consolidation and several others too.<br /><br />
            </p>
          </div>
    </div>
  );
};

export default TimeToSwitch;
